import { Pipe, PipeTransform } from '@angular/core';
import { formatPhoneNumber } from '@pixels/client/utils/phone-number';

@Pipe({
  name: 'phoneNumber',
  standalone: true
})
export class PhoneNumberPipe implements PipeTransform {
  transform(value: string | undefined): string {
    return value ? formatPhoneNumber(value) : '';
  }
}

